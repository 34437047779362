<template>
    <!-- <v-card class="mb-1 white"> -->
    <div class="white">
        <div class="d-flex flex-wrap justify-space-between" align="left">
        <!-- <div style="display: flex; flex-direction: row; flex-wrap: wrap;" align="left"> -->
            <div style="width: 32%;" v-for="specializationData in specializations" :key="specializationData.specialization_key">
                <specializationSubComponent :specialization_data="specializationData" :pageName="pageName"></specializationSubComponent>
            </div>
        </div>
    </div>
        
    <!-- </v-card> -->
</template>

<script>
    import specializationSubComponent from './specializationSubComponent.vue';

    export default {
        name: 'specializationsComponent',
        components: {
            specializationSubComponent
        },
        props: {
            specialization_list: {
                type: Array,
                requied: true
            },
            language: {
                type: String,
                required: true
            },
            pageName:{
                type: String,
                requied: false
            }
        },
        data() {
            return {
                specializations: [],
            }
        },
         watch:{
            specialization_list: function(){
            },
            language: function(){
            }
        },
        mounted() {
            if (this.language == 'mm') {
                // this.specialization_list.forEach((specialization) => {
                //     this.specializations.push({
                //         _id: specialization._id,
                //         specialization_key: specialization.specialization_key,
                //         specialization_name: specialization.specialization_name_mm,
                //         image_filename: specialization.image_filename
                //     });
                // });
                this.specializations = this.specialization_list;
            } else {
                this.specializations = this.specialization_list;
            }

            if (this.specialization_list.length === 5) {
                this.specializations.push({
                    view_more: true
                });
            }
        },
        methods:{
            updatespecialization: function(update){
                this.specializations=update;
                if (update.length === 5) {
                    this.specializations.push({
                        view_more: true
                    });
                }
            }
        }
    }
</script>

<style>
.heading{
    font-size: 14px;
    font-weight: bold;
}
</style>
